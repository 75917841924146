/* General Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  display: flex;
  width: 100%;
  height: 90%;
  max-width: 1200px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: #ffffff;
}

/* Adjust box to fit half the page */
.box {
  flex: 1; /* Take equal space */
  display: flex;
  flex-direction: column;
  min-width: 50%; /* Ensure each box is at least half the container width */
}

/* Input Box */
.input-box {
  border-right: 1px solid #ccc;
}

/* Button Containers */
.button-container-top,
.button-container-bottom {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 10px;
}

/* Code Editor */
.code-editor {
  display: flex;
  flex-grow: 1;
  overflow: hidden; /* Prevent overflow from affecting buttons */
}

.line-numbers {
  background-color: #2e2e2e;
  color: #ccc;
  text-align: right;
  padding: 10px;
  font-family: 'Courier New', monospace;
  border-right: 1px solid #ccc;
  width: 40px;
}

textarea {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  font-family: 'Courier New', monospace;
  background-color: #2e2e2e;
  color: #fff;
  resize: none;
  outline: none;
  transition: border-color 0.3s;
  overflow-y: auto; /* Allow vertical scrolling */
}

textarea:focus {
  border-color: #007bff;
}

.output-box {
  flex: 1; /* Ensure output box takes equal space */
  min-width: 50%; /* Ensure the output box is at least half the container width */
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
  background-color: #f8f8f8;
}

/* Button Styles */
button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
  color: white;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Run Button */
.run-button {
  background-color: #19a32d; /* Set background color */
  color: #fafafa; /* Change text color to blue for visibility */
  font-weight: bold; /* Make text bold */
}

.run-button:hover {
  background-color: #1fb235; /* Lighten on hover */
  transform: translateY(-2px);
}

/* Check Syntax Button */
.check-syntax-button {
  background: linear-gradient(45deg, #2b7a92, #2a666d); /* Soft blue gradient */
}

.check-syntax-button:hover {
  background: linear-gradient(45deg, #2a666d, #2b7a92);
  transform: translateY(-2px);
}

/* Paste Button */
.paste-button {
  background: linear-gradient(45deg, #7d9fc9, #6b8b72);
}

.paste-button:hover {
  background: linear-gradient(45deg, #7d9fc9, #6b8b72);
  transform: translateY(-2px);
}

/* Upload Button */
.upload-button {
  background: linear-gradient(45deg, #ffc107, #e0a800);
}

.upload-button:hover {
  background: linear-gradient(45deg, #e0a800, #ffc107);
  transform: translateY(-2px);
}

/* Clear Button */
.clear-button {
  background: linear-gradient(45deg, #dc3545, #c82333);
}

.clear-button:hover {
  background: linear-gradient(45deg, #c82333, #dc3545);
  transform: translateY(-2px);
}

/* Download Button */
.download-button {
  background: linear-gradient(45deg, #17a2b8, #138496);
}

.download-button:hover {
  background: linear-gradient(45deg, #138496, #17a2b8);
  transform: translateY(-2px);
}

.file-input {
  display: none;
}
